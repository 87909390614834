import { PlusOutlined, SearchOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Checkbox, Form, Input, InputNumber, Popconfirm, Space, Table, Typography, Tooltip} from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import AppContext from '../../../AppContext';
import { addCategory, deleteCategory, getPricingCategory } from '../../../networking/NetworkingPricing';
import AlertBox from '../../shared/AlertBox';
import NewForwardPricing from './NewForwardPricing';
import NewSpotPricing from './NewSpotPricing';

const NewPricingScreen = () => {
    const context = useRef(useContext(AppContext));
    const [form] = Form.useForm();

    const [pricingCategory, setPricingCategory] = useState([]);
    const [reloadCategory, setReloadCategory] = useState(false);
    const [spotPricingSelected, setSpotPricingSelected] = useState(false);
    const [forwardPricingSelected, setForwardPricingSelected] = useState(false);
    const [categoryID, setCategoryID] = useState(0);
    const [selectedCategoryName, setSelectedCategoryName] = useState(0);

    const [editingKey, setEditingKey] = useState(0);
    const [firstime, setFirsttime] = useState(true);
    const [currentPage, setCurrentPage] = useState();

    const [filteringSomething, setFilteringSomething] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [searchArray, setSearchArray] = useState({});
    const searchInput = useRef();

    const [currentCategoryID, setCurrentCategoryID] = useState('');
    const [currentIsDefault, setCurrentIsDefault] = useState(false);
    const [currentUpdateToken, setCurrentUpdateToken] = useState('');

    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    useEffect(() => {
        const loadPricingCategory = async () => {
            context.current.startLoading();
            const json = await getPricingCategory();
            context.current.finishLoading();
            if (json != null && json.pricingCategories != null) {
                const categories = json.pricingCategories
                    .map((c) => ({ ...c, 'Is Default?': c['Is Default?'] ? 'Yes' : 'No', key: `Pricing-${c['Category ID']}` }));
                setPricingCategory(categories);
            } else {
                setPricingCategory([]);
            }
        };
        loadPricingCategory();
    }, [reloadCategory]);

    const cancel = (page) => {
        setEditingKey(0);
        setFirsttime(true);
        if (typeof page === 'number') {
            setCurrentPage(page);
        }

        if (pricingCategory[0]['Category ID'] === 0) {
            let auxPricingCategories = [...pricingCategory];
            auxPricingCategories.shift();
            setPricingCategory(auxPricingCategories);
        }
        setCurrentCategoryID(0);
        setCurrentUpdateToken('');
    };

    const isEditing = (record) => record['Category ID'] === editingKey;

    const edit = (record) => {
        if (pricingCategory[0]['Category ID'] === 0) {
            let auxCountries = [...pricingCategory];
            auxCountries.shift();
            setPricingCategory(auxCountries);
        }

        form.setFieldsValue({
            UpdateToken: '',
            'Is Default?': record['Is Default?'] === 'Yes',
            ...record
        });
        setCurrentIsDefault(record['Is Default?'] === 'Yes');
        setEditingKey(record['Category ID']);
        setCurrentCategoryID(record['Category ID']);
        setCurrentUpdateToken(record['UpdateToken']);
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
        setFilteringSomething(true);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
        setFilteringSomething(false);
    };

    const settingSearchStateValues = (value, col) => {
        setSearchText(value);
        setSearchedColumn(col);
        setSearchArray(...(searchArray[col] = value));
        setFilteringSomething(true);
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        searchInput.current = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : []);
                    }}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        width: 188,
                        marginBottom: 8,
                        display: 'block'
                    }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            handleReset(clearFilters);
                        }}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            settingSearchStateValues(selectedKeys[0], dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )
    });

    const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
        const onChange = (e) => {
            switch (title) {
                case 'Is Default?': {
                    setCurrentIsDefault(e.target.checked);
                    form.setFieldsValue({
                        'Is Default?': e.target.checked
                    });
                    break;
                }
                default:
                    break;
            }
        };

        let inputNode = null;
        switch (inputType) {
            case 'number': {
                inputNode = <InputNumber style={{ width: '100%', textAlignLast: 'center' }} />;
                break;
            }
            case 'check': {
                inputNode = (
                    <Checkbox onChange={onChange} checked={currentIsDefault}>
                        Is Default
                    </Checkbox>
                );
                break;
            }
            default: {
                inputNode = <Input style={{ textAlign: 'center' }}/>;
                break;
            }
        }

        let styleEdit = { margin: 0 };

        if ((record !== undefined && record.isNew !== undefined && record.isNew === true) || editing) {
            styleEdit = {
                paddingBottom: 10,
                paddingTop: 10,
                margin: 0
            };
        }

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={styleEdit}
                        rules={[
                            {
                                required: true,
                                message: `Please Input ${title}!`
                            }
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    const save = async (record) => {
        try {
            const row = await form.validateFields();
            console.log(row);
            const newData = [...pricingCategory];
            const index = newData.findIndex((item) => record['Category ID'] === item['Category ID']);
            if (index > -1) {
                const userID = localStorage.getItem('UserID');
                const model = {
                    categoryID: pricingCategory[index]['Category ID'] !== 0 ? currentCategoryID : 0,
                    categoryName: row['Category Name'],
                    defaultCategory: row['Is Default?'],
                    updateToken: currentUpdateToken,
                    userID: userID
                };
                context.current.startLoading();
                const json = await addCategory(model);
                context.current.finishLoading();
                if (json != null) {
                    if (json.httpStatusCode !== 200) {
                        setShowAlert(true);
                        setAlertTitle('Error');
                        setAlertMessage(json.httpErrorMessage);
                        setReloadCategory(!reloadCategory);
                        setEditingKey(0);
                        setFirsttime(true);
                    } else {
                        setShowAlert(true);
                        setAlertTitle('Success');
                        setAlertMessage('Pricing Category successfully saved.');
                        setReloadCategory(!reloadCategory);
                        setEditingKey(0);
                        setFirsttime(true);
                        setCurrentCategoryID(json.catogoryId);
                        setCurrentUpdateToken(json.updateToken);
                    }
                }
            }
            console.log(index);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const deleteRecord = async (record) => {
      if(record.ForwardsPricing === 0 && record.SpotsPricing === 0)
      {
        setSpotPricingSelected(false);
        setForwardPricingSelected(false);
        setCategoryID(0);
        setSelectedCategoryName('');

        const userID = localStorage.getItem('UserID');
        context.current.startLoading();
        const json = await deleteCategory(record['Category ID'], userID);
        context.current.finishLoading();
        if (json != null) {
            if (json.httpStatusCode !== 200) {
                setShowAlert(true);
                setAlertTitle('Error');
                setAlertMessage(json.httpErrorMessage);
                setReloadCategory(!reloadCategory);
            } else {
                setShowAlert(true);
                setAlertTitle('Success');
                setAlertMessage('Pricing Category successfully removed.');
                setReloadCategory(!reloadCategory);
            }
        }
      }else
      {
        setAlertTitle('Error');
        setAlertMessage('This Pricing cannot be deleted as there are Categories linked to it. Please delete the Categories first, then delete this Pricing.');
        setShowAlert(true);

        if(record.SpotsPricing > 0)
        {
          selectSpotPricing(record);
        }else{
          selectForwardPricing(record);
        }
      }
    };

    const columns = [
        {
            title: 'Category Name',
            dataIndex: 'Category Name',
            width: '10%',
            editable: true,
            align: 'center',
            sorter: (a, b) => {
                return a['Category Name'].localeCompare(b['Category Name']);
            },
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('Category Name')
        },
        {
            title: 'Is Default?',
            dataIndex: 'Is Default?',
            width: '10%',
            editable: true,
            align: 'center',
            sorter: (a, b) => {
                return a['Is Default?'].localeCompare(b['Is Default?']);
            },
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Actions',
            dataIndex: 'Actions',
            align: 'center',
            width: '15%',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <a onClick={() => save(record)} style={{ marginRight: 8 }}>
                            Save
                        </a>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <div>
                        <Space size="middle">
                            <Typography.Link disabled={editingKey !== 0 || pricingCategory[0]['Category ID'] === 0} onClick={() => selectSpotPricing(record)}>
                                Spot Pricing
                            </Typography.Link>
                            <Typography.Link disabled={editingKey !== 0 || pricingCategory[0]['Category ID'] === 0} onClick={() => selectForwardPricing(record)}>
                                Forward Pricing
                            </Typography.Link>
                            <Tooltip title="Edit">
                                <Typography.Link disabled={editingKey !== 0 || pricingCategory[0]['Category ID'] === 0} onClick={() => edit(record)}>
                                    <EditOutlined />
                                </Typography.Link>
                            </Tooltip>
                            <Popconfirm title="Sure to delete?" onConfirm={() => deleteRecord(record)}>
                                <Tooltip title="Delete">
                                    <a disabled={editingKey !== 0 || pricingCategory[0]['Category ID'] === 0}><DeleteOutlined/></a>
                                </Tooltip>
                            </Popconfirm>
                        </Space>
                    </div>
                );
            }
        }
    ];

    const selectSpotPricing = (record) => {
        if (spotPricingSelected && categoryID === record['Category ID']) {
            setSpotPricingSelected(false);
            setCategoryID(0);
            setSelectedCategoryName('');
        } else {
            setCategoryID(record['Category ID']);
            setSpotPricingSelected(true);
            setForwardPricingSelected(false);
            setSelectedCategoryName(`${record['Category Name']} - Spot Pricing Detail`);
        }
    };

    const selectForwardPricing = (record) => {
        if (forwardPricingSelected && categoryID === record['Category ID']) {
            setForwardPricingSelected(false);
            setCategoryID(0);
            setSelectedCategoryName('');
        } else {
            setCategoryID(record['Category ID']);
            setForwardPricingSelected(true);
            setSpotPricingSelected(false);
            setSelectedCategoryName(`${record['Category Name']} - Forward Pricing Detail`);
        }
    };

    const handleBackLink = () => {
        setCategoryID(0);
        setForwardPricingSelected(false);
        setSpotPricingSelected(false);
        setSelectedCategoryName('');
    };

    const addNewPricingCategory = () => {
        let actual = [...pricingCategory];
        if (actual[0] != null && actual[0]['Category ID'] === 0) {
            return;
        } else {
            actual.unshift({
                'key': 'PricingCategory-new',
                'Category ID': 0,
                'Category Name': '',
                'Is Default?': false,
                UpdateToken: '',
                isNew: true
            });
            setPricingCategory(actual);
            setCurrentPage(1);
            form.setFieldsValue({
                'Category Name': '',
                'Is Default?': false,
                ...actual
            });
            setCurrentUpdateToken('');
            setCurrentCategoryID(0);
        }
    };

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        let type = '';

        switch (col.dataIndex) {
            case 'Is Default?': {
                type = 'check';
                break;
            }
            default: {
                type = 'text';
                break;
            }
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: type,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record)
            })
        };
    });

    const closeAlert = () => {
        setShowAlert(false);
    };

    function itemRender(current, type, originalElement) {
        if (type === 'prev') {
            return <a>Previous</a>;
        }
        if (type === 'next') {
            return <a>Next</a>;
        }
        return originalElement;
    }

    return (
        <div>
            <div className="breadcrumb-div">
                {categoryID === 0 ? (
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item className="breadcrumb-item-bold">Pricing</Breadcrumb.Item>
                    </Breadcrumb>
                ) : (
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item className="breadcrumb-item" onClick={handleBackLink}>
                            Pricing
                        </Breadcrumb.Item>
                        <Breadcrumb.Item className="breadcrumb-item-bold">{selectedCategoryName}</Breadcrumb.Item>
                    </Breadcrumb>
                )}
            </div>
            <Form form={form} component={false}>
                <Table
                    components={{ body: { cell: EditableCell } }}
                    dataSource={pricingCategory}
                    columns={mergedColumns}
                    size="small"
                    pagination={{
                        onChange: cancel,
                        showQuickJumper: true,
                        itemRender: itemRender,
                        current: currentPage,
                        defaultCurrent: 1,
                        size: 'default'
                    }}
                    footer={() => (
                        <Button
                            type="dashed"
                            onClick={addNewPricingCategory}
                            block
                            icon={<PlusOutlined />}
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            disabled={editingKey !== 0 || filteringSomething || (pricingCategory[0] !== undefined && pricingCategory[0]['Category ID'] === 0)}
                        >
                            Add a Pricing Category
                        </Button>
                    )}
                />
            </Form>
            {categoryID !== 0 && spotPricingSelected && <NewSpotPricing categoryID={categoryID} onAddOrDeleteSpotPricing={()=>{setReloadCategory(!reloadCategory);}} />}
            {categoryID !== 0 && forwardPricingSelected && <NewForwardPricing categoryID={categoryID} onAddOrDeleteSpotPricing={()=>{setReloadCategory(!reloadCategory);}} />}
            <AlertBox id="alert-pricing" open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert} />
        </div>
    );
};

export default NewPricingScreen;
